<template>
    <div data-aos="fade-up" class="introduction-contents">
        <div class="introduction-contents__btn-group mb-20px mb-md-0">
            <v-row align="center" justify="center">
                <v-col cols="auto">
                    <btn-arrow-primary prev color="grey lighten-1" class="swiper-btn swiper-btn-prev" />
                </v-col>
                <v-col cols="auto" class="d-md-none">
                    <span class="line"></span>
                </v-col>
                <v-col cols="auto">
                    <btn-arrow-primary next color="grey lighten-1" class="swiper-btn swiper-btn-next" />
                </v-col>
            </v-row>
        </div>
        <swiper ref="swiperIntroduction" :options="swiperOptionIntroduction" class="swiper swiper--introduction">
            <swiper-slide v-for="(item, key) in lawyer" :key="key">
                <v-row class="row--xxs">
                    <v-col>
                        <router-link :to="key === 'lawyer01' ? '/introduction/lawyer-yoon' : '/introduction/lawyer/' + key" tile class="introduction-contents__item pb-20px pb-md-30px pb-lg-40px">
                            <div class="introduction-contents__image">
                                <div class="introduction-contents__image__inner image-card" :style="'background-image: url(/images/lawyer/list/'+item.title+'.jpg)'"></div>
                            </div>
                            <v-card color="primary" rounded="sm" class="introduction-contents__item__card v-card--none-active-bg mw-md-300px">
                                <div class="pa-20px pa-md-30px pa-lg-40px d-flex flex-column align-center justify-center text-center">
                                    <txt class="white--text font-weight-bold line-height-1 font-secondary mb-4px mb-md-8px">
                                        {{item.titleEn}}
                                    </txt>
                                    <v-row justify="center" align="center" class="row--xxs">
                                        <v-col cols="auto">
                                            <tit class="white--text tit--sm">{{item.title}}</tit>
                                        </v-col>
                                        <v-col cols="auto">
                                            <txt class="white--text">
                                                <span v-if="item.ceo">대표 </span>변호사
                                            </txt>
                                        </v-col>
                                    </v-row>
                                    <icon-arrow-primary size="x-large" class="mt-16px mt-md-32px"/>
                                </div>
                            </v-card>
                        </router-link>
                    </v-col>
                    <v-col cols="auto">
                        <p class="font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase" >Lawfirm Taesung</p>
                    </v-col>
                </v-row>    
            </swiper-slide>
        </swiper>
    </div>
</template> 

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { lawyer } from "@/assets/data/lawyer.js";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,

        BtnArrowPrimary,
        IconArrowPrimary,
        Tit,
        Txt,
    },
    data() {
        return {
            swiperOptionIntroduction: {
                loop: true,
                loopedSlides: 5,
                slidesPerView: 1,
                spaceBetween: 40,
                speed: 500,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: ".introduction-contents__btn-group .swiper-btn-next",
                    prevEl: ".introduction-contents__btn-group .swiper-btn-prev",
                },
                breakpoints: {
                    768:{
                        spaceBetween: 60,
                    },
                    1024:{
                        spaceBetween: 120,
                    },
                    1200:{
                        spaceBetween: 218,
                    },
                },
            },
            lawyer,
        }
    } 
}
</script>

<style lang="scss" scoped>
.introduction-contents{
    position: relative;
    max-width: 484px;
    margin: 0 auto;
    &__image{
        width: 100%;
        height: 0;
        padding-top: calc((696 / 464) * 100%);
        position: relative;
        &__inner{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            top: 0;
            left: 0;
            opacity: 1;
            visibility: visible;
            transition: 0.15s ease-out;
        }
    }
    &__item{
        display: block;
        position: relative;
        &__card{
            max-width: calc(100% - 36px);
            position: absolute;
            bottom: 0;
            left: 16px;
            width: 100%;
            opacity: 0;
            visibility: visible;
            transition: 0.15s ease-out;
        }
    }
    ::v-deep{
        .swiper-container{
            overflow: visible !important;
        }
        .swiper-slide:not(.swiper-slide-active){
            cursor: default;
            pointer-events: none;
        }
        .swiper-slide.swiper-slide-active{
            .introduction-contents__image::after{
                opacity: 0;
                visibility: visible;
            }
            .introduction-contents__item__card{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__btn-group{
        .line{
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .introduction-contents{
        &__item{
            &__card{
                left: -30px;
            }
            &:hover{
                .introduction-contents__item__card{
                    &.primary{
                        background-color: var(--v-primary-darken4) !important;
                    }
                }
            }
        }
        .swiper-btn{
            position: absolute;
            top: 50%;
            z-index: 5;
            &-prev{
                left: -40px;
                transform: translate(-100%, -50%);
            }
            &-next{
                right: -40px;
                transform: translate(100%, -50%);
            }
        }
    }
}
@media (min-width:1024px){
    .introduction-contents{
        &__item{
            &__card{
                left: -40px;
            }
            &:hover{
                .introduction-contents__item__card{
                    &.primary{
                        background-color: var(--v-primary-darken4) !important;
                    }
                }
            }
        }
        .swiper-btn{
            &-prev{
                left: -80px;
            }
            &-next{
                right: -80px;
            }
        }
    }
}
@media (min-width:1200px){
}

</style>