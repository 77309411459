var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "introduction-contents",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('div', {
    staticClass: "introduction-contents__btn-group mb-20px mb-md-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary', {
    staticClass: "swiper-btn swiper-btn-prev",
    attrs: {
      "prev": "",
      "color": "grey lighten-1"
    }
  })], 1), _c('v-col', {
    staticClass: "d-md-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary', {
    staticClass: "swiper-btn swiper-btn-next",
    attrs: {
      "next": "",
      "color": "grey lighten-1"
    }
  })], 1)], 1)], 1), _c('swiper', {
    ref: "swiperIntroduction",
    staticClass: "swiper swiper--introduction",
    attrs: {
      "options": _vm.swiperOptionIntroduction
    }
  }, _vm._l(_vm.lawyer, function (item, key) {
    return _c('swiper-slide', {
      key: key
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', [_c('router-link', {
      staticClass: "introduction-contents__item pb-20px pb-md-30px pb-lg-40px",
      attrs: {
        "to": key === 'lawyer01' ? '/introduction/lawyer-yoon' : '/introduction/lawyer/' + key,
        "tile": ""
      }
    }, [_c('div', {
      staticClass: "introduction-contents__image"
    }, [_c('div', {
      staticClass: "introduction-contents__image__inner image-card",
      style: 'background-image: url(/images/lawyer/list/' + item.title + '.jpg)'
    })]), _c('v-card', {
      staticClass: "introduction-contents__item__card v-card--none-active-bg mw-md-300px",
      attrs: {
        "color": "primary",
        "rounded": "sm"
      }
    }, [_c('div', {
      staticClass: "pa-20px pa-md-30px pa-lg-40px d-flex flex-column align-center justify-center text-center"
    }, [_c('txt', {
      staticClass: "white--text font-weight-bold line-height-1 font-secondary mb-4px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.titleEn) + " ")]), _c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('tit', {
      staticClass: "white--text tit--sm"
    }, [_vm._v(_vm._s(item.title))])], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('txt', {
      staticClass: "white--text"
    }, [item.ceo ? _c('span', [_vm._v("대표 ")]) : _vm._e(), _vm._v("변호사 ")])], 1)], 1), _c('icon-arrow-primary', {
      staticClass: "mt-16px mt-md-32px",
      attrs: {
        "size": "x-large"
      }
    })], 1)])], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase"
    }, [_vm._v("Lawfirm Taesung")])])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }