var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Lawfirm taesung & P.E",
            "tabActive": "변호사 소개",
            "bg": "/images/sub/visual/sv-introduction.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    attrs: {
      "tit": "변호사 소개",
      "titAdd": "Introduction"
    }
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, _vm._l(_vm.lawyer, function (item, key) {
    return _c('v-col', {
      key: key,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', [_c('router-link', {
      staticClass: "lawyer-item",
      attrs: {
        "to": key === 'lawyer01' ? '/introduction/lawyer-yoon' : '/introduction/lawyer/' + key
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": '/images/lawyer/list/' + item.title + '.jpg',
        "aspect-ratio": 420 / 630,
        "max-width": "420"
      }
    }), _c('div', {
      staticClass: "lawyer-item__contents pa-16px pa-md-30px pa-lg-40px"
    }, [_c('txt', {
      staticClass: "white--text font-weight-bold line-height-1 font-secondary mb-4px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.titleEn) + " ")]), _c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "auto"
      }
    }, [_c('tit', {
      staticClass: "tit--sm white--text"
    }, [_vm._v(_vm._s(item.title))])], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "auto"
      }
    }, [_c('txt', {
      staticClass: "white--text"
    }, [item.ceo ? _c('span', [_vm._v("대표 ")]) : _vm._e(), _vm._v("변호사")])], 1)], 1), _c('icon-arrow-primary', {
      staticClass: "white--text mt-16px mt-md-32px",
      attrs: {
        "size": "x-large"
      }
    })], 1)], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase"
    }, [_vm._v("Lawfirm Taesung")])])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }